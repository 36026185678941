import {getCMSClient} from '@tetra-next/graphql-client';

import {useSubdomain} from './useSubdomain';

export const useCMSClient = () => {
  const subdomain = useSubdomain();
  return getCMSClient({
    subdomain,
  });
};
